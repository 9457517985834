import React, { useRef } from 'react';

import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxDatePicker from '.../components/formComponents/LxDatePicker';
import LxFloatField from '.../components/formComponents/LxFloatField';
import LxIntField from '.../components/formComponents/LxIntField';
import LxRadioGroup from '.../components/formComponents/LxRadioGroup';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';
import LxTimePicker from '.../components/formComponents/LxTimePicker';

const getMetaData = (itemSchema, formMetaData) => {
    return (formMetaData ?? null) !== null ?
        formMetaData[itemSchema.metaDataKey]
        :
        {};
};

const getFieldData = (itemSchema, formData, recordContext) => {
    if (formData && formData.tables && recordContext) {
        return {
            ...recordContext.getDataPoint(formData, itemSchema.tableName, itemSchema.fieldName)
        };
    } else {
        return {
            value: '',
            options: []
        };
    }
};

const getRecord = (itemSchema, formData, recordContext) => {
    if (formData && formData.tables && recordContext) {
        return {
            ...recordContext.getRecord(formData, itemSchema.tableName)
        };
    } else {
        return null;
    }
};

//Renamed to shouldComponentSkipUpdate because when using hooks and React.memo, false means rerender and true means skip rerender, which is opposite shouldComponentUpdate
const shouldComponentSkipUpdate = (props, nextProps) => {
    let wasHighlighted = Array.isArray(props.highlightedFieldKeyArray) ? props.highlightedFieldKeyArray[0] === props.itemSchema.uniqueKey : false;
    let isHighlighted = Array.isArray(nextProps.highlightedFieldKeyArray) ? nextProps.highlightedFieldKeyArray[0] === nextProps.itemSchema.uniqueKey : false;
    let previousDataPoint = props.dataPoint ? props.dataPoint : getFieldData(props.itemSchema, props.formData, props.recordContext);
    let nextDataPoint = nextProps.dataPoint ? nextProps.dataPoint : getFieldData(nextProps.itemSchema, nextProps.formData, nextProps.recordContext);
    let previousRecordIsLocked = props.isLocked ? props.isLocked : getRecord(props.itemSchema, props.formData, props.recordContext)?.isLocked;
    let nextRecordIsLocked = nextProps.isLocked ? nextProps.isLocked : getRecord(nextProps.itemSchema, nextProps.formData, nextProps.recordContext)?.isLocked;

    if (nextProps.itemSchema.displayName !== props.itemSchema.displayName) {
        return false;
    } else if (JSON.stringify(nextDataPoint) !== JSON.stringify(previousDataPoint)) {
        return false;
    } else if (JSON.stringify(getMetaData(nextProps.itemSchema, nextProps.formMetaData).selectionSet) !== JSON.stringify(getMetaData(props.itemSchema, props.formMetaData).selectionSet)) {
        return false;
    } else if (JSON.stringify(nextProps.itemSchema) !== JSON.stringify(props.itemSchema)) {
        return false;
    } else if (isHighlighted !== wasHighlighted) {
        return false;
    } else if (previousRecordIsLocked !== nextRecordIsLocked) {
        return false;
    } else {
        return true;
    }
};

function LxDynamicFormField(props) {
    const {
        itemSchema, formData, dataPoint, recordContext, formMetaData, itemMetaData, isLocked, viewName,
        settings, autoFocus, options = {}, onBlur, inputRef, inputOnly, forceSelectionSet, forceDisabled,
        highlightedFieldKeyArray, clearHighlightedField, triggerScroll, disabled, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress
    } = props;
    const row = options.row;
    const selectionSetForced = options.forceSelectionSet || forceSelectionSet || itemSchema.selectionSet;
    let targetRecord = getRecord(itemSchema, formData, recordContext);
    let calculatedDataPoint = dataPoint ? dataPoint : getFieldData(itemSchema, formData, recordContext);
    const [internalValue, setInternalValue] = React.useState(calculatedDataPoint.value);
    let highlighted = Array.isArray(highlightedFieldKeyArray) && highlightedFieldKeyArray[0] ? highlightedFieldKeyArray[0] === itemSchema.uniqueKey : false;
    let calculatedItemMetaData = itemMetaData ? itemMetaData : (formMetaData ? formMetaData[itemSchema.metaDataKey] : {});
    let ItemComponent;
    let calculatedDisabled = forceDisabled || itemSchema.readOnly || calculatedItemMetaData.forcedReadOnly || !calculatedDataPoint.isValidChild || (isLocked || targetRecord?.isLocked) || disabled;
    let itemDisplayName = (itemSchema.displayName ?? '') !== '' ? itemSchema.displayName : calculatedItemMetaData.displayName;
    let elementsCacheData = formData !== undefined ? formData !== null ? formData.formDemographicsCacheElements !== undefined ? [...formData.formDemographicsCacheElements, ...formData.formCacheElements] : [] : [] : []
    let fieldElement = elementsCacheData.find(element => element.element === calculatedDataPoint.uniqueKey);

    let emrFieldInfo = {
        emrApolloOption: fieldElement !== undefined ? fieldElement.apolloOption : undefined,
        emrConfirmationStatus: fieldElement !== undefined ? fieldElement.status : undefined,
        emrConflictStatus: fieldElement !== undefined ? fieldElement.conflictStatus : undefined,
        emrElement: fieldElement !== undefined ? fieldElement.element : undefined,
        emrElementDefaultValue: fieldElement !== undefined ? fieldElement.elementDefaultValue : undefined,
        emrMappingStatus: fieldElement !== undefined ? fieldElement.mappedStatus : undefined,
        emrOptions: fieldElement !== undefined ? fieldElement.emrOptions : undefined,
        emrMappingErrorDesc: calculatedDataPoint.mappingExpressionError === true ? calculatedDataPoint.mappingExpressionErrorDesc : '',
        emrDisabled: calculatedDisabled,
        emrRemark: fieldElement !== undefined ? fieldElement.remark : undefined,
    };

    let handleOptimisticUpdates = (newValue) => {
        setInternalValue(newValue);
    };

    const itemRef = useRef(null);

    //On blur needs to take a collection of data points, even though a Dynamic Form Field only ever changes one. This is because other Dynamic Form Items like the MultiColumnCombo can change multiple fields at once
    let handleBlur = (tableName, fieldName) => (value) => {
        let calculatedDataPoint = dataPoint ? dataPoint : getFieldData(itemSchema, formData, recordContext);
        if (typeof onBlur === 'function' && (calculatedDataPoint.value ?? "") !== (value ?? "")) {
            let dataPoints = [];
            dataPoints.push({
                tableName: calculatedDataPoint.tableName,
                fieldName: calculatedDataPoint.fieldName,
                value: value,
                keyValue: calculatedDataPoint.keyValue
            });
            onBlur(dataPoints);
            if (typeof clearHighlightedField === 'function' && highlighted) {
                clearHighlightedField();
            }
        }
    };

    //TODO Need to implement override on defaultInputType form itemSchema
    switch (calculatedItemMetaData?.defaultInputType) {
        case 'Text':
            ItemComponent = LxTextField;
            break;
        case 'CheckBox':
            ItemComponent = LxCheckbox;
            break;
        case 'SelectionSet':
            ItemComponent = LxSelectField;
            break;
        case 'RadioGroup':
            if (selectionSetForced) {
                ItemComponent = LxSelectField;
                break;
            }
            ItemComponent = LxRadioGroup;
            break;
        case 'DatePicker':
            ItemComponent = LxDatePicker;
            break;
        case 'TimePicker':
            ItemComponent = LxTimePicker;
            break;
        case 'Single':
        case 'Double':
            ItemComponent = LxFloatField;
            break;
        case 'Int':
        case 'Short':
            ItemComponent = LxIntField;
            break;
        default:
            ItemComponent = LxTextField;
            break;
    }

    React.useEffect(() => {
        //When the datapoint value changes, that means the backend has pushed a new value for this field, and the internal value should update to match. 
        setInternalValue(calculatedDataPoint.value);
    }, [calculatedDataPoint.value]);

    React.useEffect(() => {
        if (highlighted && itemRef?.current?.scrollIntoView) {
            itemRef.current.scrollIntoView();
            triggerScroll(itemRef.current);
        }
    });

    return (
        <div ref={itemRef} style={{ width: '100%', height: '100%' }}>
            <ItemComponent
                key={itemSchema.uniqueKey}
                title={itemDisplayName}
                descriptions={Array.isArray(calculatedItemMetaData?.descriptions) && calculatedItemMetaData?.descriptions.length > 0 ? calculatedItemMetaData?.descriptions : typeof calculatedItemMetaData.description === "string" ? calculatedItemMetaData.description : undefined}
                value={internalValue ?? null}
                valueOptions={calculatedDataPoint.options}
                hasMappings={Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0}
                settings={settings}
                min={calculatedItemMetaData.min}
                max={calculatedItemMetaData.max}
                disabled={calculatedDisabled}
                error={!calculatedDisabled && calculatedDataPoint.validationError !== null}
                validationErrorSeverity={calculatedDataPoint.validationErrorSeverity}
                helperText={!calculatedDisabled ? calculatedDataPoint.validationError : null}
                titleTooltip={`${itemSchema.tableName}.${itemSchema.fieldName}`}
                options={calculatedItemMetaData?.selectionSet}
                onChange={handleOptimisticUpdates}
                onBlur={handleBlur(itemSchema.tableName, itemSchema.fieldName)}
                row={row}
                allowDeselect
                autoFocus={highlighted ? highlighted : autoFocus}
                uniqueKey={itemSchema.uniqueKey}
                tabIndex={itemSchema.tabIndex}
                threeState={!calculatedItemMetaData?.required}
                forceBooleanFalse={calculatedItemMetaData?.forceBooleanFalse}
                labelPosition={itemSchema.labelPosition}
                forcedFocus={highlighted}
                borderStyle={highlighted ? { outline: 'solid 1px #0f8067', backgroundColor: 'rgba(37, 165, 137, 0.3)', border: 'solid 10px #ff000000' } : undefined}
                inputRef={inputRef}
                inputOnly={inputOnly}
                metaDataKey={itemSchema.metaDataKey}
                mappingElementsStatus={mappingElementsStatus}
                onUpdateMappingStatus={onUpdateMappingStatus}
                defaultValue={calculatedDataPoint.value}
                formLoadProgress={formLoadProgress}
                fhirPopupSelector={highlighted ? highlighted : false}
                fieldInformation={calculatedItemMetaData?.fieldInformation}
                fieldInfo={[itemSchema.tableName, itemSchema.fieldName, viewName]}
                emrInfo={emrFieldInfo}
            />
        </div>
    );
}

export default React.memo(LxDynamicFormField, shouldComponentSkipUpdate);