import axios from 'axios';

import Request from './request';
import userManager from '.../utils/userManager';

const MedicalCopilotApi = new Request(window.env.API_ROUTE);

export const pushToError = (error, mrn) => {
    if (!(error instanceof axios.Cancel)) {
        // let protocol = `${window.location.protocol}`,
        // host = window.location.hostname,
        // path = window.location.pathname ? `${window.location.pathname.replace(/\/$/, '')}` : '',
        // port = window.location.port ? `:${window.location.port}` : '';
        // if(error.response.status === 401){
        //     let page = `/#/`;
        //     window.location.assign(`${protocol}//${host}${path}${port}`);
        //     userManager.getUser().then((user) => {
        //         userManager.signoutRedirect({ 'id_token_hint': user.id_token });
        //         userManager.removeUser(); // removes the user data from sessionStorage
        //     });
        // }
        // else{
           
        //     let page = `/#/${typeof mrn !== 'undefined' ? `patient/${mrn}/` : ''}error?error=${encodeURI(error.message)}`,
        //     response = error.response ? error.response.data ? `&response=${encodeURI(error.response.data)}` : '' : '';

        // window.location.assign(`${protocol}//${host}${path}${port}${page}${response}`);
        // }
        let protocol = `${window.location.protocol}`,
            host = window.location.hostname,
            path = window.location.pathname ? `${window.location.pathname.replace(/\/$/, '')}` : '',
            port = window.location.port ? `:${window.location.port}` : '',
            page = `/#/${typeof mrn !== 'undefined' ? `patient/${mrn}/` : ''}error?error=${encodeURI(error.message)}`,
            response = error.response ? error.response.data ? `&response=${encodeURI(error.response.data)}` : '' : '';

        window.location.assign(`${protocol}//${host}${path}${port}${page}${response}`);
    }
};

export const chestPainEvalApi = {
    getResult: (formData, save) => MedicalCopilotApi.apiRequest(`/v1.0/ChestPainEvaluation/chestPainResult?save=${save}`, 'POST', formData),
    getEvents: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/ChestPainEvaluation/chestPainEvent/${ssPatientId}`),
    createEvent: (chestPainEventDto) => MedicalCopilotApi.apiRequest(`/v1.0/ChestPainEvaluation/chestPainEvent`, 'POST', chestPainEventDto),
    getChestPainDataAndResult: (eventId) => MedicalCopilotApi.apiRequest(`/v1.0/ChestPainEvaluation/chestPainObject/${eventId}`),
    getChestPainLabResults: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/ChestPainEvaluation/troponinLabResult/${ssPatientId}`)
};

export const demographicsApi = {
    getByMrn: (mrn) => MedicalCopilotApi.apiRequest(`/v1.0/demographics/getPatientByMrn/${mrn}`),
    search: (firstName = '', lastName = '', mrn = '') => MedicalCopilotApi.apiRequest(`/v1.0/demographics/searchPatientRecords?firstName=${firstName}&lastName=${lastName}&mrn=${mrn}`),
    createPatient: (mrn, firstName, lastName, dateOfBirth) => MedicalCopilotApi.apiRequest(`/v1.0/demographics/createPatient?firstName=${firstName}&lastName=${lastName}&mrn=${mrn}&dateOfBirth=${dateOfBirth}`, 'POST')
};

export const emrMappingApi = {
    getEmrMappings: (tableName = '', fieldName = '', viewName = '') => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetEmrMappings?tableName=${tableName}&fieldName=${fieldName}&viewName=${viewName}`),
    getApolloFields: (tableName = '', viewName = '', formName = '') => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetApolloFields?eventTable=${tableName}&viewName=${viewName}&formName=${formName}`),
    getEmrMappingElements: (emrMappingName) => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetEmrMappingElements?emrMappingName=${emrMappingName}`),
    createEmrMapping: (emrMappingDef) => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/CreateEmrMApping`, 'POST', emrMappingDef),
    updateEmrMapping: (emrMappingName, emrMappingDef) => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/UpdateEmrMapping?emrMappingName=${emrMappingName}`, 'PUT', emrMappingDef),
    deleteEmrMapping: (emrMappingName) => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/DeleteEmrMapping?emrMappingName=${emrMappingName}`, 'DELETE'),
    updateEmrFieldMappings: (tableName, fieldName, viewName, emrMappingDefs) => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/UpdateEmrFieldMappings?viewName=${viewName}&tableName=${tableName}&fieldName=${fieldName}`, 'PUT', emrMappingDefs),
    getFormList: () => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetFormList`, 'GET', undefined, false),
    getEventTables: () => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetEventTables`),
    getViewObjects: () => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetViewObjects`),
    getEmrSources: () => MedicalCopilotApi.apiRequest(`/v1/EmrMapping/GetEmrSources`)
};

export const formApi = {
    getFormSchema: (formName) => MedicalCopilotApi.apiRequest(`/v1/Form/FormSchema/${formName}`),
    getFullFormSchemaFromSchema: (formSchema) => MedicalCopilotApi.apiRequest(`/v1/Form/FormSchema/GetFullFormSchemaFromSchema`, 'POST', formSchema),
    getFormData: (ssPatientId, emrPatients, eventId, formName) => MedicalCopilotApi.apiRequest(`/v1/Form/GetFormData/${ssPatientId}/${eventId}/${formName}`, 'PATCH', emrPatients),
    updateFormData: (ssPatientId, eventId, formName, formData) => MedicalCopilotApi.apiRequest(`/v1/Form/FormData/${ssPatientId}/${eventId}/${formName}`, 'PUT', formData),
    createEvent: (ssPatientId, formName) => MedicalCopilotApi.apiRequest(`/v1/Form/CreateEvent/${ssPatientId}/${formName}`, 'POST'),
    getFormHeader: (formName) => MedicalCopilotApi.apiRequest(`/v1/Form/GetFormHeader/${formName}`),
    getFormList: () => MedicalCopilotApi.apiRequest(`/v1/Form/GetFormList`, 'GET', undefined, false),
    getFormTables: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1/Form/GetFormTables/${ssPatientId}`, 'GET'),
    getEventList: (formName, ssPatientId) => MedicalCopilotApi.apiRequest(`/v1/Form/GetEvents/${formName}/${ssPatientId}`),
    getMetaData: (formName, element) => MedicalCopilotApi.apiRequest(`/v1/Form/GetMetaData/${formName}`, 'PATCH', element),
    getFormValidation: (ssPatientId, formName, formData) => MedicalCopilotApi.apiRequest(`/v1/Form/ValidateFormData/${ssPatientId}/${formName}`, 'PATCH', formData),
    getEventTables: () => MedicalCopilotApi.apiRequest(`/v1/Form/GetEventTables`),
    getViewObjects: () => MedicalCopilotApi.apiRequest(`/v1/Form/GetViewObjects`),
    getEventTableFields: (eventTable, viewName) => MedicalCopilotApi.apiRequest(`/v1/Form/GetEventTableFields/${eventTable}?viewName=${viewName}`),
    validateFormSchema: (formSchema) => MedicalCopilotApi.apiRequest(`/v1/Form/ValidateDynamicFormLayout`, 'POST', formSchema),
    saveFormSchema: (formSchema) => MedicalCopilotApi.apiRequest(`/v1/Form/SaveDynamicFormLayout`, 'POST', formSchema),
    deleteFormSchema: (formName) => MedicalCopilotApi.apiRequest(`/v1/Form/DeleteDynamicFormLayout/${formName}`, 'DELETE'),
    deleteRecord: (tableName, recordId) => MedicalCopilotApi.apiRequest(`/v1/Form/DeleteRecord/${tableName}/${recordId}`, 'DELETE'),
    deletePatient: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1/Demographics/DeletePatient/${ssPatientId}`, 'DELETE'),
    getUserRightsOverPatientRecord: () => MedicalCopilotApi.apiRequest(`/v1/Demographics/GetUserRightsOverPatientRecord`, 'GET'),
    toggleRecordLock: (tableName, recordId) => MedicalCopilotApi.apiRequest(`/v1/Form/ToggleRecordLock/${tableName}`, 'PUT', recordId),
    addAndGetNewEventId: (ssPatientId, tableName) => MedicalCopilotApi.apiRequest(`/v1/Form/AddAndGetNewEventId/${ssPatientId}/${tableName}`, 'GET'),
    getFormNameAttribute: (formName) => MedicalCopilotApi.apiRequest(`/v1/Form/GetFormNameAttribute/${formName}`)
};

export const settingsApi = {
    getSettings: (settingRequestInfo) => MedicalCopilotApi.apiRequest(`/v1/Settings/GetSettings/`, 'PATCH', settingRequestInfo),
    resetAppContext: () => MedicalCopilotApi.apiRequest(`/v1/Settings/ResetAppContext/`, 'POST'),
    getAboutInfo: () => MedicalCopilotApi.apiRequest(`/v1/Settings/GetAboutInfo/`, 'GET', undefined, false)
};

export const imagesApi = {
    getBySsDicomStudyId: (ssDicomStudyId) => MedicalCopilotApi.apiRequest(`/v1.0/images/studyid/${ssDicomStudyId}`),
    getJpeg: (imageId) => MedicalCopilotApi.apiRequest(`/v1.0/images/imageId/${imageId}.jpeg`, 'GET', undefined, false, 'arraybuffer'),
    getDicom: (imageId) => MedicalCopilotApi.apiRequest(`/v1.0/images/dicom/${imageId}.dcm`)
};

export const ipcccApi = {
    getPatientDiagAndSynd: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/patient/${ssPatientId}`),
    getCodeLibrary: () => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/codes`),
    createDiagAndSynd: (diagnosis) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/diagnosis`, 'POST', diagnosis),
    updateDiagAndSynd: (diagnosis) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/diagnosis`, 'PUT', diagnosis),
    deleteDiagAndSynd: (ssDemoAepcdiagId) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/diagnosis/${ssDemoAepcdiagId}`, 'DELETE'),
    createSurgAndIntern: (diagnosis) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/intervention`, 'POST', diagnosis),
    updateSurgAndIntern: (diagnosis) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/intervention`, 'PUT', diagnosis),
    deleteSurgAndIntern: (ssDemoAepcdiagId) => MedicalCopilotApi.apiRequest(`/v1.0/ipccc/intervention/${ssDemoAepcdiagId}`, 'DELETE')
};

export const launchpadApi = {
    getUrls: (mrn) => MedicalCopilotApi.apiRequest(`/v1.0/launchpad/patient/${mrn}`)
};

export const measurementsApi = {
    getAdultEchoMeasurement: () => MedicalCopilotApi.apiRequest(`/v1.0/Measurements/GetAdultEchoMeasurements`)
};

export const powerbiApi = {
    getUserModules: () => MedicalCopilotApi.apiRequest(`/v1.0/PowerBI/usermodules`, 'GET', undefined, false),
    getFeature: (featureId, rls) => MedicalCopilotApi.apiRequest(`/v1.0/PowerBI/accessToken/${featureId}/rls/${rls}`, 'GET', undefined, false)
};

export const riskApi = {
    getEvents: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/risk/events/${ssPatientId}`),
    createEvent: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/risk/eventSts/${ssPatientId}`, 'POST'),
    getFormSchema: () => MedicalCopilotApi.apiRequest(`/v1.0/risk/formSchema`),
    getFormData: (ssPatientId, emrPatients, eventId) => MedicalCopilotApi.apiRequest(`/v1.0/risk/stsSurgicalRiskGetFormData/${ssPatientId}/${eventId}`, 'PATCH', emrPatients),
    getRiskResult: (ssPatientId, formData) => MedicalCopilotApi.apiRequest(`/v1.0/risk/stsSurgicalRiskResults/${ssPatientId}`, 'PATCH', formData),
    getFormValidation: (ssPatientId, formData) => MedicalCopilotApi.apiRequest(`/v1.0/risk/stsSurgicalRiskFormValidation/${ssPatientId}`, 'PATCH', formData),
    getResultAndValidation: (ssPatientId, formData) => MedicalCopilotApi.apiRequest(`/1.0/risk/stsSurgicalRiskResultAndValidation/${ssPatientId}`, 'PATCH', formData),
    saveFormData: (ssPatientId, eventId, formData) => MedicalCopilotApi.apiRequest(`/v1.0/risk/stsSurgicalRiskFormData/${ssPatientId}/${eventId}`, 'PUT', formData)
};

export const sabermedicsApi = {
    getPhysicianSummary: () => MedicalCopilotApi.apiRequest(`/v1.0/sabermedics/GetPhysicianSummary`),
    getOeRatioResult: (surgeon, procedureId, numberOfMonths) => MedicalCopilotApi.apiRequest(`/v1.0/sabermedics/GetOeRatio/${surgeon}/${procedureId}/${numberOfMonths}`),
    getOeRatioFilterOptions: () => MedicalCopilotApi.apiRequest(`/v1.0/sabermedics/GetOeRatioFilterOptions`, 'GET', undefined, false),
    getPhysicianRankings: (procedureId, numberOfMonths) => MedicalCopilotApi.apiRequest(`/v1.0/sabermedics/GetPhysicianRankings/${procedureId}/${numberOfMonths}`)
};

export const surgeonScorecardApi = {
    getAllOeRecords: (months) => MedicalCopilotApi.apiRequest(`/v1.0/surgeonScorecard${convertMonths(months)}`),
    getScorecard: (surgeon, procedureId, months) => MedicalCopilotApi.apiRequest(`/v1.0/surgeonScorecard/${surgeon}/procedure/${procedureId}${convertMonths(months)}`),
    getSurgeons: () => MedicalCopilotApi.apiRequest(`/v1.0/surgeonScorecard/surgeons`),
    getProcedures: () => MedicalCopilotApi.apiRequest(`/v1.0/surgeonScorecard/procedures`)
};

export const trendsApi = {
    getTrendsCharts: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/Trends/GetTrendsCharts/${ssPatientId}`),
    getTrendsChartData: (ssPatientId, chartId) => MedicalCopilotApi.apiRequest(`/v1.0/Trends/GetTrendsChartData/${ssPatientId}/${chartId}`),
    getTrendsChartsWithData: (ssPatientId) => MedicalCopilotApi.apiRequest(`/v1.0/Trends/GetTrendsChartsWithData/${ssPatientId}`),
    updateTrendsChart: (chart) => MedicalCopilotApi.apiRequest(`/v1.0/Trends`, 'PUT', chart),
    createTrendsChart: (chart) => MedicalCopilotApi.apiRequest(`/v1.0/Trends`, 'POST', chart),
    deleteTrendsChart: (chart) => MedicalCopilotApi.apiRequest(`/v1.0/Trends`, 'DELETE', chart)
};

const convertMonths = (months) => typeof months === 'undefined' ? '' : '?months=' + months;

export const worklistApi = {
    getWorklists: () => MedicalCopilotApi.apiRequest(`/v1.0/Worklist/Worklists`),
    getWorklistFilterResults: (filterNameSpace, filterName) => MedicalCopilotApi.apiRequest(`/v1.0/Worklist/FilterResults/${filterNameSpace}/${filterName}`),
    getFilterRecordDto: (tableName, keyValue) => MedicalCopilotApi.apiRequest(`/v1.0/Worklist/GetFilterRecord/${tableName}/${keyValue}`)
};

export const auditApi = {
    userlogin: () => MedicalCopilotApi.apiRequest(`/v1.0/Audit/userlogin`, 'POST'),
    userlogout: () => MedicalCopilotApi.apiRequest(`/v1.0/Audit/userlogout`, 'POST')
};

export const staffApi = {
    getStaffs: () => MedicalCopilotApi.apiRequest(`/v1.0/Staff/getStaffs`),
    saveStaff: (staffDef) => MedicalCopilotApi.apiRequest(`/v1.0/Staff/saveStaff`, 'POST', staffDef),
    deleteStaff: (staffId) => MedicalCopilotApi.apiRequest(`/v1.0/Staff/deleteStaff/${staffId}`, 'DELETE'),
    getSelectionSetElements: (selectionSetName) => MedicalCopilotApi.apiRequest(`/v1.0/Staff/getSelectionSetElements/${selectionSetName}`),
    getStaffLookupSecurity: () => MedicalCopilotApi.apiRequest(`/v1.0/Staff/getStaffLookupSecurity`)
};

export const selectionSetApi = {
    getSelectionSets: () => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/getSelectionSets`),
    saveSelectionSet: (selectionSetDef) => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/saveSelectionSet`, 'POST', selectionSetDef),
    deleteSelectionSet: (selectionSetID) => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/deleteSelectionSet/${selectionSetID}`, 'DELETE'),
    getSelectionSetElements: (selectionSetName) => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/getSelectionSetElementsForEditor/${selectionSetName}`),
    saveSelectionSetElements: (selectionSetElmentDef) => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/saveSelectionSetElements`, 'POST', selectionSetElmentDef),
    deleteSelectionSetElement: (selectionSetElementID) => MedicalCopilotApi.apiRequest(`/v1.0/SelectionSet/deleteSelectionSetElement/${selectionSetElementID}`, 'DELETE')
};

export const lookupEditorApi = {
    getLookupTablesList: () => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/getLookupTablesList`, 'GET', undefined, false),
    getLookupTableData:(lookupTableName) => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/getSelectedLookupTable/${lookupTableName}`),
    saveSelectedLookupTable:(newRecord,lookupTableName,isNewRecord) => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/saveSelectedLookupTable/${lookupTableName}/${isNewRecord}`, 'POST',newRecord),
    deleteSelectedLookupTable:(lookupTableIDElementID,lookupTableName,identity) => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/deleteLookupTableRow/${lookupTableName}/${identity}`, 'POST',lookupTableIDElementID),
    getLookupTablePrimaryKey:(lookupTableName) => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/getLookupTablePrimaryKey/${lookupTableName}`),
    isIdentityColumn:(lookupTableName) => MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/isIdentityColumn/${lookupTableName}`),
    getSelectedLookupTableDataType:(lookupTableName) =>MedicalCopilotApi.apiRequest(`/v1.0/LookupEditor/getSelectedLookupTableDataType/${lookupTableName}`)
};

export const reportApi = {
    getReports: (eventTableName, eventId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/getReports/${eventTableName}/${eventId}`),
    getReportTemplates: (eventTableName, formName) => MedicalCopilotApi.apiRequest(`/v1.0/Report/getReportTemplates/${eventTableName}/${formName}`),
    createRequested: (eventTableName, formName, eventId, reportTemplateName, reportTemplateId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/createRequested/${eventTableName}/${formName}/${eventId}/${reportTemplateName}/${reportTemplateId}`, 'POST'),
    getReport: (eventTableName, eventId, reportId, action) => MedicalCopilotApi.apiRequest(`/v1.0/Report/getReport/${eventTableName}/${eventId}/${reportId}/${action}`),
    getReportStatus: () => MedicalCopilotApi.apiRequest(`/v1.0/Report/getReportStatus`),
    deleteRequested: (eventTableName, eventId,reportId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/deleteRequested/${eventTableName}/${eventId}/${reportId}`, 'DELETE'),    
    ensureReportAccessRequested: (eventTableName, eventId, reportId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/ensureReportAccessRequested/${eventTableName}/${eventId}/${reportId}`, 'POST'),    
    statusChangeRequested: (eventTableName, eventId, reportId, proposedStatus, userName) => MedicalCopilotApi.apiRequest(`/v1.0/Report/statusChangeRequested/${eventTableName}/${eventId}/${reportId}/${proposedStatus}/${userName}`, 'POST'),
    isEsignatureEnabled: (proposedStatus) => MedicalCopilotApi.apiRequest(`/v1.0/Report/isEsignatureEnabled/${proposedStatus}`, 'POST'),
    eSignatureAuthenticated: (userName, password) => MedicalCopilotApi.apiRequest(`/v1.0/Report/eSignatureAuthenticated/${userName}/${password}`, 'POST'),
    getViewOnCreation: (eventTableName, eventId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/getViewOnCreation/${eventTableName}/${eventId}`),
    reportSecurity: () => MedicalCopilotApi.apiRequest(`/v1.0/Report/reportSecurity`),
    assertReportExists: (eventTableName, eventId, reportId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/assertReportExists/${eventTableName}/${eventId}/${reportId}`, 'POST'),
    checkForLockOnFinalize: (eventTableName, eventId, reportId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/checkForLockOnFinalize/${eventTableName}/${eventId}/${reportId}`),
    lockOnFinalize: (eventTableName, eventId) => MedicalCopilotApi.apiRequest(`/v1.0/Report/lockOnFinalize/${eventTableName}/${eventId}`)
};
